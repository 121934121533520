<template>
  <section id="new-connection">
    <b-row class="match-height">
      <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>
              Логи
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-table
              :data="data"
              :columns="columns"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import { HubConnectionBuilder } from '@microsoft/signalr'
import { Table } from 'buefy'

Vue.use(Table)

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BRow,
    BCol,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  props: {
  },
  data() {
    return {
      message: null,
      connection: null,
      data: [],
      columns: [
        {
          field: 'timestamp',
          label: 'Дата записи',
        },
        {
          field: 'level',
          label: 'Тип',
        },
        {
          field: 'message',
          label: 'Сообщение',
        },
      ],
    }
  },
  computed: {
  },
  mounted() {
    this.connection = new HubConnectionBuilder()
      .withUrl(`${process.env.VUE_APP_BASE_URL}/logs`)
      .build()

    this.$http.get('/logger')
    // eslint-disable-next-line no-unused-vars
      .then(response => {
        this.data = response.data
      })

    this.connection.on('AddLogs', buffs => {
      console.log('uffs')
      this.data.push(buffs)
    })
  },
  created() {
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
